import styled from 'styled-components';

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
`;

export const PaginationItem = styled.li`
  margin: 0 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 5px;
  background-color: ${props => (props.selected ? props.theme.color.primary : 'transparent')};
  color: ${props => (props.selected ? '#fff' : props.theme.color.primary)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${props => !props.disabled && props.theme.color.primary};
    color: ${props => !props.disabled && '#fff'};

    
  & > div {
      border-color: white; 
    }
  }

`;

export const Arrow = styled.div`
  border: solid ${props => props.theme.color.primary};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: ${props => (props.left ? 'rotate(135deg)' : 'rotate(-45deg)')};
  -webkit-transform: ${props => (props.left ? 'rotate(135deg)' : 'rotate(-45deg)')};


  
`;