import React from "react";
import * as T from "./tableNotaFiscal.style.jsx";
import { TransactionRow } from "./TransactionRow.jsx";

export const Table = ({ dados }) => {
  return (
    <T.Tabela>
      <T.TabelaHeader>
        <T.TabelaRow>
          <T.TabelaHeaderCell width={"4rem"}>ID</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Escola</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Município</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Convênio</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Total de transações</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Total de entradas</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Total de saídas</T.TabelaHeaderCell>
          <T.TabelaHeaderCell>Notas Fiscais</T.TabelaHeaderCell>
        </T.TabelaRow>
      </T.TabelaHeader>
      <T.TabelaBody>
        {dados?.map((consulta, index) => (
          <TransactionRow index={index} consulta={consulta} key={index} />
        ))}
      </T.TabelaBody>
    </T.Tabela>
  );
};
