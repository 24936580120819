import PopUp from "../../../components/PopUp/popUp";
import { IoClose } from "react-icons/io5";
import { InputsExtrato } from "../../Extrato/InputsExtrato/InputsExtrato";
import { Button } from "../../../components/globalComponents.style";

export const PopUpFiltragem = ({
    setBotaoFiltro,
    botaoFiltro,
    filtrosParaSelecionar,
    unidadesACalcular,
    tipoUnidadeSelecionada,
    direcsPermitidas,
    filtrarPorConvenio,
}) => {
    return (
        <PopUp width="50%" height="50%">
            <Button
                height="35px"
                width="10%"
                primary
                onClick={() => setBotaoFiltro(!botaoFiltro)}
            >
                <IoClose />
            </Button>
            {tipoUnidadeSelecionada ? (
                <div>
                    {unidadesACalcular.length} {tipoUnidadeSelecionada}S para calcular
                </div>
            ) : (
                "Nenhuma unidade selecionada"
            )}
            {direcsPermitidas.length === 1 ? (
                ""
            ) : (
                <InputsExtrato
                    label="DIREC"
                    type={2}
                    options={filtrosParaSelecionar.direc}
                />
            )}
            {direcsPermitidas.length === 1 ? (
                ""
            ) : (
                <InputsExtrato
                    label="DRAE"
                    type={2}
                    options={filtrosParaSelecionar.drae}
                />
            )}
            <InputsExtrato
                label="PROGRAMA GOVERNO"
                type={2}
                options={filtrosParaSelecionar.programaGoverno}
                onChange={(option) => {
                    filtrarPorConvenio(option.value);
                }}
            />
        </PopUp>
    );
};