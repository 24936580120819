import axios from "axios";
import IHttpClientServices from "./IHttpClientServices";

// type Direc = {
//   id: string;
//   ativo: boolean;
//   nome: string;
//   cnpj: string;
//   fee: string;
//   municipio: string;
//   convenio: string;
//   agencia: string;
//   conta: string;
//   createdAt: string;
//   updatedAt: string;
// };

export default class DirecsService implements IHttpClientServices {
  token: string | null;
  url: string | null;
  headers: { authorization: string };

  constructor() {
    if (!process.env.REACT_APP_FEE) {
      throw new Error('A variável de ambiente REACT_APP_FEE não está definida');
    }

    this.url = `${process.env.REACT_APP_FEE}/direc`;

    const dados = sessionStorage.getItem("token");

    this.token = dados ? JSON.parse(dados) : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
  }

  buscarPorId(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  criar(obj: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
  atualizarPorId(id: string, obj: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
  deletarPorId(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  async listar(): Promise<any[]> {
    try {
      const response = await axios.get(`${this.url}`);
      return response.data;
    } catch (error) {
      alert("Erro ao listar direcs: " + error);
      throw error;
    }
  }
}
