import axios from "axios";
import IHttpClientServices from "./IHttpClientServices";



export default class PermissoesService implements IHttpClientServices {
    token: string | null;
    url: string | null;
    headers: { authorization: string };

    constructor() {
        this.url = `${process.env.REACT_APP_FEE}/permissao` || null;

        const dados = sessionStorage.getItem("token");
        this.token = dados ? JSON.parse(dados) : null;
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
    }

    listar(): Promise<any[]> {
        throw new Error("Method not implemented.");
    }
    buscarPorId(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    criar(obj: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    atualizarPorId(id: string, obj: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    deletarPorId(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }

    async listarPermissoes(): Promise<any[]> {
        if (this.token === null) {
            alert("Token não encontrado");
            return []
        };

        const response = await axios.get(`${this.url}/`);
        return response.data;
    }
}