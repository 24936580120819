
import { FormConsultaExtrato, HeaderExtrato } from "../../pages/Extrato/Extrato.style.jsx";
import { InputsExtrato } from "../../pages/Extrato/InputsExtrato/InputsExtrato.jsx";
import { ButtonExtrato } from "../../pages/Extrato/Button/Button.jsx";
import { Button } from "../globalComponents.style.jsx";
import { geraExtratoFundoInvestimento } from "../../reports/extratoAplicacaoFinanceira.jsx";
import { IoPrintSharp } from "react-icons/io5";
import Extrato from "../Extrato/extrato.jsx";
import ExtratoFI from "../ExtratoFundoInvestimento/extratoFundoInvestimento.jsx";
import { ExtratoContaCorrente } from "../../reports/extratoContaCorrente.jsx";
import buscar from "../../assets/buscar.png";
import * as S from "./formConsulta.style.jsx";
import axios from "axios";
import { toast } from "sonner";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { useNavigate, useParams } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { ExtratoProgramaGoverno } from "./service/consultarExtratoProgramaGoverno.js";

export const ExtratoBancario = ({
    transactionClicked, setTransactionClicked,
    setUltimasConsultas, ultimasConsultas }) => {

    const [saldoContaCorrente, setSaldoContaCorrente] = useState("none");
    const [saldoAplicacao, setSaldoAplicacao] = useState("none");
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState("none");
    const [options, setOptions] = useState([]);
    const [loadedEntidade, setLoadedEntidade] = useState(true);
    const [selectedOptionOperacao, setSelectedOptionOperacao] = useState("none");
    const [dados, setDados] = useState({});
    const [dadosFI, setDadosFI] = useState([]);
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [primeiroMes, setPrimeiroMes] = useState(null);
    const [ultimoMes, setUltimoMes] = useState(null);
    const [unidade, setUnidade] = useState();
    const { draesPermitidas, direcsPermitidas, escolasPermitidas } = useMyPermissoesContext();
    const [mostrarHistorico, setMostrarHistorico] = useState(true);

    const { token } = useMyUserContext();

    const consultarSaldoAplicaçãoFinanceira = async () => {
        const { agencia, conta } = selectedOption;

        let data = JSON.stringify({
            agencia: agencia?.replace(/.$/, ""),
            conta: conta?.replace(/.$/, ""),
        });

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/SaldoAplicacaoFinanceira/`,
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        const valor = await axios(config)
            .then(function (response) {
                return JSON.stringify(response.data);
            })
            .catch(function (error) {
                toast.error("Erro ao consultar saldo de aplicação financeira");
                if (error.response.status == 400) {
                }
            }
            );

        if (valor) {
            setSaldoAplicacao(JSON.parse(valor));
        }
    };

    const consultarContaCorrente = async () => {
        const { agencia, conta } = selectedOption;

        let data = JSON.stringify({
            agencia: agencia?.replace(/.$/, ""),
            conta: conta?.replace(/.$/, ""),
        });

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/SaldoContaCorrente/`,
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        const valor = await axios(config)
            .then(function (response) {

                return JSON.stringify(response.data);
            })
            .catch(function (error) {
                toast.error("Erro ao consultar saldo de aplicação financeira");
                if (error.response.status == 400) {
                }
            });

        if (valor) {
            setSaldoContaCorrente(JSON.parse(valor));
        }
    };

    var optionsUnidades = []

    if (direcsPermitidas.length > 0) {
        optionsUnidades.push({ value: "DIREC", label: "DIREC" });
    }
    if (draesPermitidas.length > 0) {
        optionsUnidades.push({ value: "DRAE", label: "DRAE" });
    }
    if (escolasPermitidas.length > 0) {
        optionsUnidades.push({ value: "ESCOLA", label: "ESCOLA" });
    }


    const optionsOperacao = [
        {
            value: "ExtratoOrgaoRepasseProgramaDoGoverno",
            label: "Conta Corrente Orgão Repasse",
        },
        {
            value: "ExtratoFundosInvestimentoOrgaoRepasse",
            label: "Fundo Investimento Orgão Repasse",
        },
    ];
    const handleCPFChange = useCallback((option) => {
        setSelectedOption(option ? option.value : null);
    }, [selectedOption]);


    const navigate = useNavigate();


    const params = useParams();

    function procurarEntidadePorAgenciaEConta(agencia, conta) {

        if (unidade === "DIREC" || unidade === "direc") {
            return direcsPermitidas.find(
                (entidade) =>
                    entidade.agencia === agencia && entidade.conta === conta
            );
        } else if (unidade === "DRAE" || unidade === "drae") {
            return draesPermitidas.find(
                (entidade) =>
                    entidade.agencia === agencia && entidade.conta === conta
            );
        } else if (unidade === "ESCOLA" || unidade === "escola") {
            return escolasPermitidas.find(
                (entidade) =>
                    entidade.agencia === agencia && entidade.conta === conta
            );
        }
    }

    useEffect(() => {
        if (params.unidade && direcsPermitidas.length > 0 || draesPermitidas.length > 0 || escolasPermitidas > 0) {
            setUnidade(params.unidade);
            setSelectedOptionOperacao(params.operacao);
            setDataInicial(desformatarData(params?.dataInicial));
            setDataFinal(desformatarData(params?.dataFinal));
            setSelectedOption(procurarEntidadePorAgenciaEConta(params.agencia, params.conta));
            // realizarConsulta()
        }
    }, [params, direcsPermitidas, draesPermitidas, escolasPermitidas]);

    function clickUltimaConsulta(ultimaConsulta) {
        setUnidade(ultimaConsulta.unidade);
        setSelectedOptionOperacao(ultimaConsulta.operacao);
        setDataInicial(desformatarData(ultimaConsulta?.dataInicial));
        setDataFinal(desformatarData(ultimaConsulta?.dataFinal));
        setSelectedOption(procurarEntidadePorAgenciaEConta(ultimaConsulta.agencia, ultimaConsulta.conta));
    }

    useEffect(() => {
        var selectEntidades = []

        if (direcsPermitidas.length > 0 || draesPermitidas.length > 0 || escolasPermitidas > 0) {
            switch (unidade) {
                case "DIREC":
                case "direc":
                    selectEntidades = direcsPermitidas;
                    break;
                case "DRAE":
                case "drae":
                    selectEntidades = draesPermitidas;
                    break;
                case "ESCOLA":
                case "escola":
                    selectEntidades = escolasPermitidas;
                    break;
                default:
                    break;
            }

            const newOptions = selectEntidades?.map((entidade, index) => ({
                value: {
                    cnpj: entidade.cnpj,
                    convenio: entidade.convenio,
                    agencia: entidade.agencia,
                    conta: entidade.conta,
                    nome: entidade.nome,
                },
                label: `${entidade.cnpj} - ${entidade.nome} - ${entidade.convenio}`,
                key: index,
            }));

            setOptions(newOptions);
            setLoadedEntidade(false);
        }

    }, [unidade, escolasPermitidas, draesPermitidas, direcsPermitidas]);





    const formatarData = (data) => {
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();


        return `${ano}-${mes}-${dia}`;
    };

    const desformatarData = (dataString) => {
        if (!dataString || dataString == undefined) return null;
        const [ano, mes, dia] = dataString?.split('-');
        return new Date(ano, mes - 1, dia);
    };

    const realizarConsulta = async () => {
        if (!dataInicial || dataFinal === "") {
            toast.error("Preencha todos os campos");
            return
        }

        const dataInicialFormatada = formatarData(dataInicial);
        const dataFinalFormatada = formatarData(dataFinal);

        // if (ultimasConsultas.find((consulta) =>
        //     consulta.agencia === selectedOption.agencia
        //     && consulta.conta === selectedOption.conta
        //     && consulta.operacao === selectedOptionOperacao
        //     && consulta.dataInicial === dataInicialFormatada
        //     && consulta.dataFinal === dataFinalFormatada)) {
        //     return;
        // }

        setLoading(true);

        navigate("/extrato/" + unidade.toLowerCase() + "/"
            + selectedOption.agencia + "/"
            + selectedOption.conta + "/"
            + selectedOptionOperacao + "/"
            + dataInicialFormatada + "/"
            + dataFinalFormatada);

        setUltimasConsultas(prevConsultas => [
            {
                unidade: unidade,
                nome: selectedOption.nome,
                agencia: selectedOption.agencia,
                conta: selectedOption.conta,
                operacao: selectedOptionOperacao,
                dataInicial: dataInicialFormatada,
                dataFinal: dataFinalFormatada,
                dataDaConsulta: new Date().toLocaleString()
            },
            ...prevConsultas
        ]);

        setSaldoAplicacao("none");
        setSaldoContaCorrente("none");
        setDados({});
        setDadosFI([]);
        //consultar conta corrente
        consultarContaCorrente();
        consultarSaldoAplicaçãoFinanceira();

        if (
            selectedOption.agencia &&
            selectedOption.conta
        ) {

            const { agencia, conta } = selectedOption;
            if (selectedOptionOperacao === "ExtratoPoupancaOrgaoRepasse") {
                let data = JSON.stringify({
                    agencia: agencia.replace(/.$/, ""),
                    conta: conta.replace(/.$/, ""),
                    variacao: 51,
                    mes: dataInicial.getMonth() + 1,
                    ano: dataInicial.getFullYear(),
                });

                let config = {
                    method: "post",
                    url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
                    headers: {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    data: data,
                };

                const resp = await axios(config)
                    .then(function (response) {
                        return JSON.stringify(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                if (resp) {
                    setDados(JSON.parse(resp));

                }
            }

            if (selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno") {
                const resposta = await ExtratoProgramaGoverno(
                    dataInicial,
                    dataFinal,
                    selectedOptionOperacao,
                    agencia,
                    conta,
                    token
                );
                setDados(resposta.data);
            }

            if (
                selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
            ) {
                setDadosFI([]);
                const resposta = await ExtratoProgramaGoverno(
                    dataInicial,
                    dataFinal,
                    selectedOptionOperacao,
                    agencia,
                    conta,
                    token
                );
                setPrimeiroMes(dataInicial.getMonth());
                setDadosFI(resposta.data.data);
            }
        } else {
            toast.error("Preencha todos os campos");
        }
        setLoading(false);
    };

    return <>
        {/* <S.ContainerFormConsulta> */}
        <HeaderExtrato>

            <div style={{
                display: "flex", flexDirection: "column", height: "100%", width: "fit-content",
                justifyContent: "end",
            }}>
                <FormConsultaExtrato onSubmit={(e) => {
                    e.preventDefault();
                    realizarConsulta();
                }}>
                    <InputsExtrato
                        type={1}
                        options={optionsUnidades}
                        onChange={(option) => setUnidade(option ? option.value : null)}
                        value={optionsUnidades.find(option => option.value === unidade)}
                        placeholder="Escolha uma unidade"
                    />

                    <InputsExtrato
                        label="CNPJ"
                        type={2}
                        options={options}
                        placeholder="Selecione um CNPJ"
                        onChange={handleCPFChange}
                        value={selectedOption} />

                    <InputsExtrato
                        label="OPERAÇÃO"
                        type={2}
                        options={optionsOperacao}
                        placeholder="Selecione uma operação"
                        onChange={(option) => setSelectedOptionOperacao(option ? option.value : null)}
                        value={options.find(option => option.value === selectedOptionOperacao)} />

                    <InputsExtrato
                        label="DATA DE INÍCIO"
                        type={2}
                        isDate={true}
                        id="dataInicial"
                        selected={dataInicial}
                        date={dataInicial}
                        placeholder="Data inicial"
                        onChange={(date) => setDataInicial(date)} />

                    <InputsExtrato
                        label="DATA DE FIM"
                        type={2}
                        isDate={true}
                        id="dataFinal"
                        selected={dataFinal}
                        date={dataFinal}
                        placeholder="Data final"
                        onChange={(date) => setDataFinal(date)}
                        minDate={dataInicial} />

                    <ButtonExtrato />
                </FormConsultaExtrato>
                <span>Extrato Bancário</span></div>


            <S.ContainerSaldo>
                <S.LabelSaldo
                    titulo="Saldo Conta Corrente:"
                    valor={saldoContaCorrente.valorDisponibilidade}
                    loading={loading}
                />
                <S.LabelSaldo
                    titulo="Saldo Aplicação:"
                    valor={saldoAplicacao.valorDisponibilidade}
                    loading={loading}
                />

                <div style={{
                    width: '100%', height: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}>
                    <Button
                        primary
                        width="100%"
                        height="50%"
                        onClick={() =>
                            selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
                                ? geraExtratoFundoInvestimento(dadosFI, selectedOption)
                                : selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno"
                                    ? ExtratoContaCorrente(dados, selectedOption)
                                    : toast.warning('Realize uma consulta')
                        }
                    >
                        <IoPrintSharp /> Imprimir
                    </Button>
                </div>
            </S.ContainerSaldo>

        </HeaderExtrato>

        {ultimasConsultas?.length > 0 && (<S.UltimaConsultaContainer

            mostrarHistorico={mostrarHistorico}>

            <h3 style={{ textAlign: "center", alignItems: "center" }}
                onClick={() => setMostrarHistorico(!mostrarHistorico)}
            >
                <FaRegEye size={20} /> Histórico de consultas
            </h3>

            {mostrarHistorico && ultimasConsultas?.map((consulta, index) => (
                <span>
                    <p style={{ color: "gray" }}>
                        {consulta.dataDaConsulta}
                    </p>
                    <p draggable={false} key={index}
                        onClick={() => clickUltimaConsulta(consulta)}
                    >
                        {consulta.operacao}
                        {consulta.nome} -
                        {consulta.operacao == "ExtratoOrgaoRepasseProgramaDoGoverno" ? " Fundo Investimento Orgão Repasse" : "Conta Corrente Orgão Repasse"} -
                        ({consulta.dataInicial.split("-").join("/")} -
                        {consulta.dataFinal.split("-").join("/")})
                    </p>
                </span>

            ))}
        </S.UltimaConsultaContainer >)
        }


        <S.ContainerFormConsulta2>

            {loading ? (
                <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div className="spinner-border" role="status" />
                </div>
            ) : (
                dadosFI.length > 0 ? (
                    <ExtratoFI
                        data={dadosFI}
                        mesInicio={primeiroMes}
                        mesFim={ultimoMes}
                        setTransactionClicked={setTransactionClicked}
                    />
                ) : (
                    Object.keys(dados).length > 0 ? (
                        <Extrato
                            data={dados}
                            unidade={unidade?.toLowerCase()}
                            agencia={selectedOption?.agencia}
                            conta={selectedOption?.conta}
                            transactionClicked={transactionClicked}
                            setTransactionClicked={setTransactionClicked} />
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10%", height: "100%" }}>
                            <a>Realize uma consulta</a>
                            <img src={buscar} alt="QR Code" draggable="false" style={{
                                width: '10rem',
                                height: '10rem',
                                marginBottom: '20px',
                                animation: '0 100% 4s linear infinite'
                            }} />

                        </div>

                    )
                )

            )}
        </S.ContainerFormConsulta2>
    </>
}