import React from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

export const Title = styled.div`
  color: #0A7BBD;
  font-size: 150%;
  font-family: 'Rawline';
  font-weight: 600;
  display: flex;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 1%;
  justify-content: space-between;
  padding-right: 6%;
`;

export const LineTitle = styled.div`
  width: 95%;
  border: 1px #E7E9EB solid;
`;

export const HeaderTitle = (props) => {
  return (
    <div style={{ width: "100%", marginLeft: "3%", marginBottom: "1%", alignItems: "center" }}>
      <Title><p>{props.title} </p></Title>
      <LineTitle />
    </div>
  );
};

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 3%;
  font-family: 'Rawline', sans-serif;
  position: relative;
  justify-content: space-between;
`;

export const InfoContainer = styled.div`
  width: 80%;
  height: 100%;
  gap: 2%;
  display: flex;
  position: relative;
`;

export const InfoBox = (props) => {
  return (
    <div style={{ width: "20%", justifyContent: "flex-start", paddingRight: "3%", height: "100%", gap: '10%', display: 'flex', fontWeight: "500", flexDirection: 'column', paddingTop: "1%", boxSizing: 'border-box' }}>
      <span style={{ fontSize: "90%", textTransform: "uppercase", display: "flex", textWrap: "nowrap", marginBottom: "1%" }}>{props.titulo}</span>
      <span style={{ color: "#0A7BBD", fontSize: "130%", marginBottom: "1%" }}>{props.valor}</span>
    </div>
  )
}

export const ImprimirContainer = styled.div`
  width: 97%;
  display: flex;
  justify-content: flex-end;
  margin-bottom:1%;
  position: relative;
`;

export const RelatorioContainer = styled.div`
  width: 97%;
  box-sizing: border-box;
  background-color: white;
  height: 75%;
  flex-direction: column;
  align-items: center;
  border: 1px #62647035 solid;
  overflow: scroll;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
`;

export const PopoverContent = styled(Popover.Content)`
font-family: 'Rawline', sans-serif;
  border-radius: 4px;
  padding: 20px;
  width: 500px;
  z-index: 99;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
`;

export const Text = styled.p`
  margin: 0;
  color: black;
  font-size: 15px;
  line-height: 19px;
  justify-content: space-between;
  font-weight: 500;
  `;

export const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`;

export const Fieldset = styled.div`

display: flex;
flex-wrap: wrap;
position: relative;
width: 100%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const MenuContainer = styled.div`
 width: 100%;
 position: sticky;
 z-index:1;
 top: 0;
 background-color: white;
 height: 10%;
 display: flex;
 justify-content: space-between;
`;

export const MenuItemsContainer = styled.div`
 width: 70%;
 height: 100%;
 display: flex;
 position: relative;
`;


export const UtilsContainer = styled.div`
width: 30%;
justify-content: flex-end;
height: 100%;
background-color: white;
display: flex;
position: relative;
`;

export const MenuItem = styled.button`
 width: 20%;
 height: 100%;
 padding-left: 12px;
 padding-right: 12px;
 padding-top: 6px;
 padding-bottom: 6px;
 background-color: ${props => props.botaoSelecionado ? "#0A7BBD26" : "white"};
 justify-content: center;
 align-items: center;
 gap: 8;
 color: ${props => props.botaoSelecionado ? "#0A7BBD" : "#000000"};
 border: ${props => props.botaoSelecionado ? "1px #0A7BBD26 solid" : "none"};
 font-family: Rawline, sans-serif;
 display: inline-flex;
 font-size: 100%;
 font-weight: 600;
 transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

 &:hover { 
  background-color: #F9F9F9;
 }
`;

export const UtilsButton = styled(MenuItem)`
  background-color: ${props => props.primary ? "#F9F9F9" : "none"};
  color: ${props => props.primary ? "black" : "#1D8241"};

  width: 50%;
  transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &:hover {
    color: #22392a;
  }
  &:active {
    color: #000000;
  }

`;
