import React, { useEffect, useState } from "react";
import {
  ButtonStyle, AlignButton, IconMenu,
  RelatorioContainer, RelatorioLink
} from "./Button.style.jsx";
import { MdBorderColor } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";
import { useMyUserContext } from "../../../hooks/MyUserContext.tsx";
import { IoHomeOutline } from "react-icons/io5";
import { TbZoomMoney } from "react-icons/tb";
import { useNavigate } from "react-router";
import TooltipAlert from "../../Tooltip/TooltipAlert.jsx";
import * as Tooltip from "@radix-ui/react-tooltip";



export default function ButtonsMenu({ setBotaoSelecionado, esconderMenu, setIsSelected, isSelected }) {

  const { direcsPermitidas, escolasPermitidas, draesPermitidas } = useMyPermissoesContext();

  const { usuarioLogado } = useMyUserContext();

  useEffect(() => {
  }, [direcsPermitidas, escolasPermitidas, draesPermitidas]);

  const navigate = useNavigate();
  return (
    <>

      <ButtonStyle
        isSelected={isSelected === 'PÁGINA INICIAL'
        }
        onClick={() => {
          navigate("/home")
          setIsSelected('PÁGINA INICIAL');
          setBotaoSelecionado('PÁGINA INICIAL');
        }}
      >
        <AlignButton esconderMenu={esconderMenu}>
          <IconMenu> <IoHomeOutline /></IconMenu>
          {esconderMenu ? 'PÁGINA INICIAL' : null}
        </AlignButton>
      </ButtonStyle >


      <ButtonStyle

        isSelected={isSelected === 'EXTRATO'
        }
        onClick={() => {
          navigate("/extrato")
          setIsSelected('EXTRATO');
          setBotaoSelecionado('EXTRATO');
        }}
      >
        <AlignButton esconderMenu={esconderMenu}>
          <IconMenu> <TbZoomMoney size={20} /></IconMenu>
          {esconderMenu ? 'EXTRATO' : null}
        </AlignButton>
      </ButtonStyle >
      {/* 
      <ButtonStyle
        isSelected={isSelected === 'NOTA FISCAL'
        }
        onClick={() => {
          navigate("/nota")
          setIsSelected('NOTA FISCAL');
          setBotaoSelecionado('NOTA FISCAL');
        }}
      >
        <AlignButton esconderMenu={esconderMenu}>
          <IconMenu> <TbFileInvoice size={20} /></IconMenu>
          {esconderMenu ? 'NOTA FISCAL' : null}
        </AlignButton>
      </ButtonStyle > */}




      {/* Botão Relatório */}
      {usuarioLogado?.relatorio === true ? (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1%" }}>
          <ButtonStyle
            isSelected={isSelected === "RELATÓRIO"}
            onClick={() => {
              setIsSelected("RELATÓRIO");
              setBotaoSelecionado("RELATÓRIO");
            }}
          >
            <AlignButton esconderMenu={esconderMenu}>
              <IconMenu>
                <HiOutlineDocumentReport />
              </IconMenu>
              {esconderMenu ? "RELATÓRIO" : null}
            </AlignButton>
          </ButtonStyle>

          {isSelected === "RELATÓRIO" ? (
            <RelatorioContainer>
              <RelatorioLink onClick={() => navigate("/relatorio/saldos")}>
                Relatório de saldos
              </RelatorioLink>

              <RelatorioLink onClick={() => navigate("/relatorio/notas-fiscais")}>
                Relatório de notas fiscais
              </RelatorioLink>

              {/* <RelatorioLink disabled>Relatório de notas fiscais (em breve)</RelatorioLink> */}



            </RelatorioContainer>
          ) : null}
        </div>
      ) : null}


      {usuarioLogado?.permissoes[0]?.nome === 'ADMIN' ?
        <ButtonStyle
          isSelected={isSelected === 'ADM'
          }
          onClick={() => {
            navigate("/admin")
            setIsSelected('ADM');
            setBotaoSelecionado('ADM');
          }}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu> <MdBorderColor /></IconMenu>
            {esconderMenu ? 'ADM' : null}
          </AlignButton>
        </ButtonStyle >
        : null}
    </>

  );
}