import { Background, Campo, GrayContainer, Info, InfoContainer, InfoTitle, QrCodeContainer, StatusContainer, StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, StyledTr, WhiteContainer, YellowContainer } from "./NotaFiscal.style"
import qrCodeImage from '../../assets/NFE-qrcode.png';
import Pagination from "../../hooks/paginacao/Paginacao";
import { useEffect, useMemo, useState } from "react";
import { useParams } from 'react-router-dom';
import APIBBService from "../../services/APIBB.ts";
import { useMyUserContext } from "../../hooks/MyUserContext.tsx";
import { toast } from "sonner";
import { Button } from "../../components/globalComponents.style.jsx";
import { IoArrowBack } from "react-icons/io5";

let PageSize = 10;

export const NotaFiscal = ({ setTransactionClicked, transactionClicked }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState(0);
    const [invoice, setInvoice] = useState(null);
    const { usuarioLogado } = useMyUserContext();
    const { agencia, conta, unidade, idNota, idTransacao, data,
        documento, subCategoria1, subCategoria2, valor, saldo, historico } = transactionClicked;

    const service = new APIBBService();

    useEffect(() => {
        if (usuarioLogado && agencia && conta && unidade && idNota && idTransacao && data) {
            service.buscarNota(agencia, conta, unidade, idNota, idTransacao, data)
                .then((response) => {
                    setInvoice(response.data);
                }).catch((error) => {
                    console.log(error);
                    toast.error("Erro ao buscar nota fiscal");
                });
        }

    }, [usuarioLogado, transactionClicked]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return invoice?.expenseDocument.items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, invoice]);

    return invoice ? (
        <Background>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: "1rem" }}>
                <Button
                    primary
                    width="fit-content"
                    onClick={() => setTransactionClicked(null)}>
                    <IoArrowBack />
                    voltar para o extrato
                </Button>
                <span>DETALHAMENTO {idNota} </span>

            </div>

            <GrayContainer>

                <Info>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '70%',
                        justifyContent: 'center',
                    }}>
                        <p>NOTA FISCAL</p>
                        <InfoContainer>
                            <Campo title="NÚMERO" descricao={invoice?.expenseDocument.number}></Campo>
                            <Campo title="Data de emissão" descricao={invoice?.expenseDocument.issueDate}></Campo>
                            <Campo title="tipo de operação" descricao={invoice?.expenseDocument.operation}></Campo>

                            <Campo title="valor total" descricao={invoice?.expenseDocument.value}></Campo>
                            <Campo title="valor de desconto" descricao={invoice?.expenseDocument.discountValue}></Campo>
                            <Campo title="Valor Total com Desconto:" descricao={invoice?.expenseDocument.totalDiscountValue}></Campo>
                        </InfoContainer>
                    </div>

                    <QrCodeContainer>
                        <img src={qrCodeImage} alt="QR Code" />
                        <InfoTitle>chave de acesso </InfoTitle>
                        <p>{invoice?.expenseDocument.accessKey}</p>
                    </QrCodeContainer>
                </Info>
            </GrayContainer>
            <YellowContainer>
                <Info>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <p>DÉBITO</p>

                        <div style={{
                            display: 'flex',
                            width: '100%',
                            gap: '1rem',
                            justifyContent: 'space-between',
                            paddingBottom: '1rem',
                            alignItems: 'center'

                        }}>
                            <Campo title="dt. balancete" descricao={data}></Campo>
                            <Campo title="documento" descricao={documento}></Campo>
                            {/* <Campo title="histórico" descricao={historico}></Campo> */}
                            <Campo title="valor" descricao={valor}></Campo>
                            <Campo title="saldo" descricao={saldo}></Campo></div>
                    </div>
                </Info>
            </YellowContainer>

            <StatusContainer
                status={Number(invoice?.expenseDocument.totalDiscountValue) > Number(valor)}>
                <Info>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <p>COMPARAÇÃO</p>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            gap: '1rem',
                            justifyContent: 'space-between',
                            paddingBottom: '1rem',
                            alignItems: 'center'

                        }}>
                            <Campo title="histórico" descricao={historico}></Campo>
                            <Campo title="Valor pago em nota fiscal" descricao={`R$ ${invoice?.expenseDocument.totalDiscountValue}`}></Campo>
                            <Campo title="Valor debitado" descricao={valor}></Campo></div>
                    </div>
                </Info>
            </StatusContainer>


            <Info>
                <WhiteContainer>
                    <p>EMISSOR</p>
                    <InfoContainer>
                        <Campo title="Razão Social" descricao={invoice?.issuer.legalName}></Campo>
                        <Campo title="CNPJ" descricao={invoice?.issuer.corporateTaxPayerRegistry}></Campo>
                        <Campo title="inscrição estadual" descricao={invoice?.issuer.stateRegistrationNumber}></Campo>
                        <Campo title="local" descricao={invoice?.issuer.countryName + ", "
                            + invoice?.issuer.cityName + " - "
                            + invoice?.issuer.stateAbbreviation + ", "
                            + invoice?.issuer.districtName + ", "
                            + invoice?.issuer.postalCode}></Campo>
                        <Campo title="telefone" descricao={invoice?.issuer.phoneNumber}></Campo>
                    </InfoContainer>
                </WhiteContainer>
                <WhiteContainer>
                    <p>RECEPTOR</p>
                    <InfoContainer>
                        <Campo title="Razão Social" descricao={invoice?.receiver.legalName}></Campo>
                        <Campo title="CNPJ" descricao={invoice?.receiver.corporateTaxPayerRegistry}></Campo>
                        <Campo title="inscrição estadual" descricao={invoice?.receiver.stateRegistrationNumber}></Campo>
                        <Campo title="local" descricao={invoice?.receiver.countryName + ", "
                            + invoice?.receiver.cityName + " - "
                            + invoice?.receiver.stateAbbreviation + ", "
                            + invoice?.receiver.districtName + ", "
                            + invoice?.receiver.postalCode}></Campo>
                        <Campo title="telefone" descricao={invoice?.receiver.phoneNumber}></Campo>
                    </InfoContainer>
                </WhiteContainer>
            </Info>


            <StyledTable>
                <StyledThead>
                    <StyledTr>
                        <StyledTh>Descrição</StyledTh>
                        <StyledTh>NCM</StyledTh>
                        <StyledTh>Quantidade</StyledTh>
                        <StyledTh>Métrica</StyledTh>
                        <StyledTh>Valor por unidade</StyledTh>
                        <StyledTh>Valor do desconto</StyledTh>
                        <StyledTh>Valor total</StyledTh>

                    </StyledTr>
                </StyledThead>
                <StyledTbody>
                    {currentTableData?.map(item => {
                        return (
                            <StyledTr>
                                <StyledTd>{item?.description}</StyledTd>
                                <StyledTd>{item?.mercosurCommonNameId}</StyledTd>
                                <StyledTd>{item?.quantity}</StyledTd>
                                <StyledTd>{item?.metric}</StyledTd>
                                <StyledTd>R$ {item?.unitValue}</StyledTd>
                                <StyledTd>R$ {item?.itemDiscountValue}</StyledTd>
                                <StyledTd>R$ {item?.totalValue}</StyledTd>

                            </StyledTr>
                        );
                    })}
                </StyledTbody>
            </StyledTable>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={invoice?.expenseDocument.items.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />


        </Background >
    ) : (<Background>Carregando...</Background>)
} 