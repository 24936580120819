import styled from 'styled-components';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 0.5rem;

    label { 
    color: ${props => props.theme.color.text[200]};
    font-size: 0.9rem;
    font-weight: semibold;
} 

`;

export const CustomSelect = styled(Select)`
    border: none;
    border-radius: 0.25rem;
    width: 100%;
    background-color:  ${props => props.type == 1 ? "white" : props.theme.color.bg[100]};
    appearance: none; 
    cursor: pointer;
    padding-right: 1.8rem;
    font-size: 0.9rem;
    box-shadow: ${props => props.type == 1 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.08)" : "none"};

    &:focus {
        border-color: ${props => props.theme.color.primary};
        outline: blue;
    }

    option {
        background-color: white;
        color: black;
        text-weight: bold;
        font-size: 0.8rem;

        &:hover {
            background-color: ${props => props.theme.color.primary};
        }
    }
`;


export const CustomDatePicker = styled(DatePicker)`
     border: none;
    border-radius: 0.25rem;
     padding-right: 1.8rem;
    font-size: 0.9rem;
    display: flex;
    padding: 0.5rem;
   width: 100%;
   transition: all 0.2s;
   background-color:  ${props => props.type == 1 ? "white" : props.theme.color.bg[100]};
   
  &:focus {
    border: none;
    outline: none;
    background-color:  ${props => props.type == 2 ? "white" : props.theme.color.bg[100]};
  }
    
  &::placeholder {
    color: #272727;
    font-size: small;
  }
`;