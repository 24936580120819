import styled from "styled-components";

export const Tabela = styled.table`
  width: 100%;
  height: 100%;
  display: table;
  align-items: start;
  justify-content: start;
  box-sizing: border-box;
  border-collapse: collapse; 
`;

export const TabelaHeader = styled.thead`
  position: sticky;
  background-color: #f7f8fa;
  display: flex;
`;


export const TabelaHeaderCell = styled.th`
  display: flex;
  font-weight: bold;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  width: ${props => props.width || null};
`;

export const TabelaDataCell = styled.td`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: ${props => props.width || null};
`;


export const TabelaBody = styled.tbody`
  color: #0a7bbd;
  background-color: white;
  width: 100%;
`;

export const TabelaRow = styled.tr`
  color: black;
  display: flex;
  background-color: ${props => props.showTransactions ? "#FDFFE7" : "white"} !important;
  height: 100%;
  width: 100%;
  padding:  0.5rem 0;
  align-items: center;
  box-shadow: ${props => props.active ? "0px 8px 8px 2px rgba(0, 0, 0, 0.05)" : "none"};
  cursor: pointer;
  z-index: ${props => props.active ? "20" : "none"};


  &:nth-child(even) {
    background-color:  ${props => props.showTransactions ? "#FDFFE7" : "#f3f3f3"} !important;
  }

  &:hover {
    z-index: 10;
    box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.09);
  }
`;

export const TabelaRowContainer = styled.div`
  color: black;
  display: flex;
  height: 100%;
  background-color: transparent;
  flex-direction: column;
  width: 100%;

  align-items: center;
  



`;


