import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from 'sonner';

import logo from "../../img/SEEC - vertical.png";

// Define a configuração de fonte uma vez
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function extratoPDFEscola(
  tipo,
  dadosConsultados,
  totalAplicacao,
  totalContaCorrente
) {



  if (!dadosConsultados || dadosConsultados.length === 0) {
    toast.warning('Dados não estão prontos para processamento.')
  }

  const dadosMapeados =   dadosConsultados
?.map((item, index) => {
    // Adiciona a lógica para alternar cores de fundo
    const fillColor = index % 2 === 0 ? "#f0f0f0" : "#FFFFFF";

    return {
      stack: [
        {
          text: index + 1,
          width: "auto",
          fillColor: fillColor,
          alignment: "center",
          fontSize: 12,
        },
        {
          text: item.nome,
          width: "auto",
          fillColor: fillColor,
          alignment: "left",
          fontSize: 12,
        },
        {
          text: item.cnpj,
          width: "auto",
          fillColor: fillColor,
          alignment: "left",
          fontSize: 12,
        },
        {
          text: item.direc,
          width: "auto",
          fillColor: fillColor,
          alignment: "left",
          fontSize: 12,
        },
        {
          text: item.drae,
          width: "auto",
          fillColor: fillColor,
          alignment: "left",
          fontSize: 12,
        },
        {
          text: item.municipio,
          width: "auto",
          fillColor: fillColor,
          alignment: "left",
          fontSize: 12,
        },
        {
          text: item && item.saldoContaCorrente ? item.saldoContaCorrente.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }) : 'R$ 0,00',
          width: "auto",
          fillColor: fillColor,
          alignment: "center",
          fontSize: 12,
        },
        {
          text: item && item.saldoAppFinanceira ? item.saldoAppFinanceira.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }) : 'R$ 0,00',
          width: "auto",
          fillColor: fillColor,
          alignment: "center",
          fontSize: 12,
        },
        {
          text: (
            item.saldoContaCorrente + item.saldoAppFinanceira
          ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          width: "auto",
          fillColor: fillColor,
          alignment: "center",
          fontSize: 12,
        },
      ],
      _minWidth: 101.162109375, // Ajuste conforme necessário
      _maxWidth: 101.162109375, // Ajuste conforme necessário
    };
  });

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: 'landscape',
    alignment: "center",
    watermark: {
      image: "test watermark",
      color: "blue",
      opacity: 0.1,
      bold: true,
      italics: false,
    },

    header: {
      text: `\n Relatório de ${tipo}`,
      fontSize: 16,
      bold: true,
      color: '#01436A',
      margin: [0, 0, 10, 10],
      alignment: "center",
    },
    content: [
      {
        stack:
          [
            {
              text:"Secretaria de Estado da Educação, do Esporte e do Lazer do Rio Grande do Norte",
              alignment: "center",
            },
           {
              text: `Data: ${new Date().toLocaleDateString()} Hora: ${new Date().toLocaleTimeString()}`,
              alignment: "center",
            }, // Add a comma here
            /*,
            
            {
              image: `data:${logo};base64 ...encodedContent...`, // Substitua pelo caminho real para o seu logotipo
              width: 50, // Ajuste conforme necessário
              alignment: 'center' // Alinha a imagem no centro
            }*/
          ],
      },
      {
        text: "\n", // Espaço entre as tabelas
      },
      {
        table: {
          headerRows: 1,
          widths: ["33%", "33%", "33%"], // Ajuste conforme necessário
          body: [
            [
              {
                text: "Valores Totais",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 14,
                colSpan: 3 // Faz o título abranger todas as três colunas
              },
              {}, // Espaços vazios para as colunas restantes
              {}
            ],
            [
              {
                text: "Total Aplicação",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
              {
                text: "Total Conta Corrente",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
              {
                text: "Total Saldo",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
            ],
            [
              {
                text: totalAplicacao.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }), // Substitua totalAplicacao pelo valor real
                alignment: "center",
                fontSize: 12,
              },
              {
                text: totalContaCorrente.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }), // Substitua totalContaCorrente pelo valor real
                alignment: "center",
                fontSize: 12,
              },
              {
                text: (totalAplicacao + totalContaCorrente).toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                ), // Substitua totalSaldo pelo valor real
                alignment: "center",
                fontSize: 12,
              },
            ],
          ],
        },
      },
      {
        text: "\n", // Espaço entre as tabelas
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"], // Ajuste conforme necessário
          body: [
            [
              {
                text: "Valores Individuais",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 14,
                colSpan: 8 // Faz o título abranger todas as três colunas
              },
              {}, // Espaços vazios para as colunas restantes
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: "ID",
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: tipo,
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: "CNPJ",
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: "Direc",
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: "Drae",
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: "Município",
                style: "tableHeader",
                bold: true,
                alignment: "center",
                fontSize: 12,
              },
              {
                text: "Saldo conta corrente",
                style: "header",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
              {
                text: "Saldo aplicação financeira",
                style: "tableHeader",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
              {
                text: "Saldo total",
                style: "tableHeader",
                alignment: "center",
                bold: true,
                fontSize: 12,
              },
            ],
            ...(dadosMapeados ?? []).map((entry) =>
              Array.isArray(entry.stack) ? entry.stack : []
            ),
          ],
          layout: "lightHorizontalLines",
          alignment: "center", // Centraliza a tabela na folha
        },
      },
    ],

    footer: function (currentPage, pageCount) {
      return {
        margin: [30, 20, 30, 0], // Ajuste a margem conforme necessário
        stack: [
          {
            canvas: [
              {
                type: 'line',
                x1: 0, y1: 0,
                x2: 1000, y2: 0,
                lineWidth: 2,
                lineColor: '#3AA25F'
              }
            ],
          },
          {
            text: [
              "Produzido pelo Grupo de Processamentos de Dados \n Grupo DEV "
            ],
            alignment: "center",
            style: {
              fontSize: 10,
              bold: true,
              color: '#01436A'
            },
            margin: [0, 5, 0, 0] // Adicionado margem para dar espaço entre a linha e o texto
          }
        ]
      };
    }
  };

  pdfMake.createPdf(docDefinition).open();
}
