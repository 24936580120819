import React, { useState, useEffect, useCallback } from "react";
import { TableBoxContainer, TableContainer } from "../../style/Adm.style.jsx";
import { useMyPermissoesContext } from "../../../../hooks/permissoes/MyPermissoesContext.jsx";
import AdicionarPopUp from "../popUps/AdicionarPopUp.jsx";
import { FaEdit } from "react-icons/fa";
import EscolasService from "../../../../services/EscolasService.ts";

export function Escola() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);
  const [loading, setLoading] = useState(true);
  const [escolasTela, setEscolasTela] = useState([]);

  const { escolasPermitidas, setEscolasPermitidas } =
    useMyPermissoesContext();

  const escolaService = new EscolasService();

  const listar = async () => {
    await escolaService.listar().then((response) => {
      setEscolasPermitidas(response.data);

    })

    if (escolasPermitidas.length > 0) setLoading(false)

  };

  useEffect(() => {
    setEscolasTela(escolasPermitidas);
    listar();
  }, []);

  function fecharPopUp() {
    listar();
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  return (<>{loading ? <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
    <div className="spinner-border" role="status" />

  </div> : (
    <TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..."
            onChange={(e) => {
              const search = e.target.value;
              const filtered = escolasPermitidas.filter(item => {
                return item.nome.toLowerCase().includes(search.toLowerCase());
              });
              setEscolasTela(filtered);
            }
            } />
          <button className="button2" onClick={adicionarNovo}>
            + ESCOLAS
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            direcs={escolasPermitidas}
            fecharPopUp={fecharPopUp}
            tipo='escola'
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo='escola'
            direcs={escolasPermitidas}
            edicao={false}
          />
        )}
        <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de ESCOLA
          </td>
        </tr>
        <table style={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "start" }}>
          <thead>

            <tr>
              <th>Nome</th>
              <th>FEE</th>
              <th>Município</th>
              <th>Convênio</th>
              <th>CNPJ</th>
              <th>Agência</th>
              <th>Conta</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {escolasTela.map((row, index) => (
              <tr key={index}>
                <td>{row.nome}</td>
                <td>{row.fee}</td>
                <td>{row.municipio}</td>
                <td>{row.convenio}</td>
                <td>{row.cnpj}</td>
                <td>{row.agencia}</td>
                <td>{row.conta}</td>
                <td>
                  <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer>)}
  </>
  );
}

export default Escola;
