//import React from "react";
import "./extrato.css";

function ResponsiveExample({ data, mesInicio, mesFim }) {
  const meses = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];
  
  function renderizaTabela(dados) {
    const array1 = ["a", "b", "c"];
    const tabelaRows = array1.map((element, index) => {
      if (element === "a") {
        return (

          // Imprime a primeira linha da tabela
          <tr key={index}>
            <td>{dados.extrato.dataSaldoAnterior}</td>
            <td>SALDO ANTERIOR</td>
            <td>
              {dados.extrato.valorSaldoAnterior.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </td>
            <td></td>
            <td></td>
            <td>
              {dados.extrato.quantidadeCotaAnterior.toLocaleString(
                "pt-BR",
                { minimumFractionDigits: 6, maximumFractionDigits: 6 }
              )}
            </td>
            <td></td>
            <td></td>
          </tr>
        );
      }
      if (element === "b") {
        // Imprime as linhas do meio da tabela
        return dados.extrato.listaLancamentosExtrato.map(
          (dado2, index) => (
            <tr key={index}>
              <td>{dado2.dataLancamento}</td>
              <td>{dado2.descricao}</td>
              <td>
                {dado2.valorLancamento.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
              <td>
                {dado2.valorIR + dado2.valorPrejuizo > 0 &&
                  (dado2.valorIR + dado2.valorPrejuizo).toLocaleString(
                    "pt-BR",
                    { style: "currency", currency: "BRL" }
                  )}
              </td>
              <td>
                {dado2.valorIOF > 0 &&
                  dado2.valorIOF.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
              </td>
              <td>
                {dado2.quantidadeCota.toLocaleString("pt-BR", {
                  minimumFractionDigits: 6,
                  maximumFractionDigits: 6,
                })}
              </td>
              <td>
                {" "}
                {dado2.valorCota > 0 &&
                  dado2.valorCota.toLocaleString("pt-BR", {
                    minimumFractionDigits: 9,
                    maximumFractionDigits: 9,
                  })}
              </td>
              <td>
                {dado2.saldoCotas > 0 &&
                  dado2.saldoCotas.toLocaleString("pt-BR", {
                    minimumFractionDigits: 6,
                    maximumFractionDigits: 6,
                  })}
              </td>
            </tr>
          )
        );
      }
      if (element === "c") {
        return (
          // Imprime a última linha da tabela
          <tr key={index}>
            <td>{dados.extrato.dataSaldoMesAnterior}</td>
            <td>SALDO ATUAL</td>
            <td>
              {dados.extrato.valorSaldoMesAnterior.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </td>
            <td></td>
            <td></td>
            <td>
              {dados.extrato.quantidadeCotaMesAnterior.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </td>
            <td></td>
            <td>
              {dados.extrato.quantidadeCotaMesAnterior.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </td>
          </tr>
        );
      }
      return null;
    });
    return (
      <div className="tableDiv">
        {/* <p className="title" style={{ display: "flex", alignSelf: "center", color: "#0a7bbd" }}>Mês {mes}/{ano}</p> */}
        <table>
          <thead>
            <tr style={{ backgroundColor: "white" }}>
              <th>Data</th>
              <th>Histórico</th>
              <th>Valor</th>
              <th>Valor IRPrej. Comp.</th>
              <th>Valor IOF</th>
              <th>Quantidade cotas</th>
              <th>Valor cota</th>
              <th>Saldo cotas</th>
            </tr>
          </thead>
          <tbody>{tabelaRows}</tbody>
        </table>

        <p className="title">Resumo do mês</p>
        <div className="row">
          <div className="column1">
            <div className="row1">SALDO ANTERIOR</div>
            <div className="row1">APLICAÇÕES (+)</div>
            <div className="row1">RESGATES(-)</div>
            <div className="row1">RENDIMENTO BRUTO(+)</div>
            <div className="row1">IMPOSTO DE RENDA(-)</div>
            <div className="row1">IOF(-)</div>
            <div className="row1">RENDIMENTO LIQUIDO(-)</div>
            <div className="row1">SALDO ATUAL</div>
          </div>
          <div className="column2">
            <div className="row1">
              {dados.extrato.valorSaldoAnterior.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
            <div className="row1">
              {dados.extrato.valorTotalAplicadoPeriodo.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorTotalResgatadoPeriodo.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorRendimentoBrutoPeriodo.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorTotalIRPeriodo.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorTotalIOFPeriodo.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorRendimentoLiquido.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorSaldoMesAnterior.toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </div>
          </div>
        </div>
        <p className="title">Valor da Cota</p>
        <div className="row">
          <div className="column1">
            <div className="row1">
              {dados.extrato.dataAfericaoValorCota}
            </div>
            <div className="row1">
              {dados.extrato.dataUltimaCotacaoCota}
            </div>
          </div>
          <div className="column2">
            <div className="row1">
              {dados.extrato.valorCota.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
            <div className="row1"></div>
          </div>
        </div>
        <p className="title">Rentabilidade</p>
        <div className="row">
          <div className="column1">
            <div className="row1">No mês</div>
            <div className="row1">No ano</div>
            <div className="row1">último 12 meses</div>
          </div>
          <div className="column2">
            <div className="row1">
              {dados.extrato.valorRentabilidadeMes.toLocaleString(
                "pt-BR",
                { minimumFractionDigits: 4, maximumFractionDigits: 4 }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorRentabilidadeAno.toLocaleString(
                "pt-BR",
                { minimumFractionDigits: 4, maximumFractionDigits: 4 }
              )}
            </div>
            <div className="row1">
              {dados.extrato.valorRentabilidadeResgateTotal.toLocaleString(
                "pt-BR",
                { minimumFractionDigits: 4, maximumFractionDigits: 4 }
              )}
            </div>
          </div>
        </div>
      </div>
    );

  }
  let valor = data?.extrato?.dataUltimaCotacaoCota?.split("/")
  // let mes = meses[valor[1] - 1];
  // let ano = valor[2];

  return (
    <div className="containerCentral">
      <h1>
        <span>Extrato Aplicação Financeira - Convênio{" "}
          {data[0]?.nomeProgramaGoverno} </span>
        <span></span>
        <span></span>
      </h1>
      {data === "sem dados" ? <div>
        <h1>Extrato Fundo de Investimento</h1>
        <p>
          {/* Mês {mes} - {data} */}
        </p>
      </div> : data?.map((dados) => renderizaTabela(dados))}




    </div>
  );
}

export default ResponsiveExample;
