import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { MyRelatorioContextProvider } from "../hooks/MyRelatorioContext";
import { MyPermissoesContextProvider } from "../hooks/permissoes/MyPermissoesContext";
import { NotaFiscal } from "../pages/NotaFiscal/NotaFiscal";
import Dashboard from "../pages/Dashboard/Dashboard.tsx";
import FormConsulta2 from "../components/FomularioConsulta/FormConsulta2.jsx";
import Relatorio from "../pages/Relatorios/Saldos/Relatorio.jsx";
import Admin from "../pages/Adm/Adm.jsx";
import RelatorioNotaFiscal from "../pages/Relatorios/NotaFiscal/RelatorioNotaFiscal.jsx";

function Rotas() {
  return (

    <MyPermissoesContextProvider>
      <MyRelatorioContextProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Carregando...</div>}>
            <Routes>
              <Route exact element={<Layout />} >
                <Route path="/home" element={<Dashboard />} />
                <Route path="extrato" element={<FormConsulta2 />} />
                <Route path="extrato/:unidade/:agencia/:conta/:operacao/:dataInicial/:dataFinal" element={<FormConsulta2 />} />
                <Route path="extrato/:unidade/:agencia/:conta/nota/:idNota/:idTransacao/:data" element={<NotaFiscal />} />
                <Route path="/relatorio/saldos" element={<Relatorio />} />
                <Route path="/relatorio/notas-fiscais" element={<RelatorioNotaFiscal />} />
                <Route path="/admin" element={<Admin />} />

              </Route>
              <Route path="/" exact element={<Login />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </MyRelatorioContextProvider>
    </MyPermissoesContextProvider >

  );
}

export default Rotas;
