
import { H1 } from '../globalComponents.style';
import '../ExtratoFundoInvestimento/extrato.css';
import { toast } from 'sonner';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

function ResponsiveExample({ data, unidade, agencia, conta, setTransactionClicked, transactionClicked }) {

  console.log(data)
  var soma = 0;
  if (data == 500) {
    toast.error("Erro de conexão com o servidor do Banco do Brasil")
    return (
      <div >
        <H1 >
          Não foi possível exibir o Extrato Bancário
        </H1>
        <p className='title' style={{ color: "red" }}>
          Erro de conexão com o servidor do Banco do Brasil.
        </p>
      </div >
    );
  } else {
    return (
      <div >
        <H1 >
          Extrato Bancário - Convênio {data.governmentProgramName}
        </H1>
        {(data.data === "sem dados" || data.data === "") ?
          <p className='title'>
            Sem lançamentos no período
          </p> :
          <table>
            <thead>
              <tr>
                <th>Dt. Balancete</th>
                <th>Histórico</th>
                <th>Documento</th>
                <th>Valor</th>
                <th>Saldo</th>
                <th>SubCategoria 1</th>
                <th>SubCategoria 2</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody >
              {data.transactions.map((dado, key) => {
                if (dado.creditDebitIndicator === "D") {
                  soma = soma - dado.value
                } else {
                  soma = soma + dado.value
                }
                if (Math.abs(soma) < 1e-10) {
                  soma = 0;
                }

                return (
                  <tr key={key}>
                    <td>{dado.valueDate}</td>
                    <td>{dado.descriptionCode} {dado.descriptionName} {dado.beneficiaryDocumentId} {dado.beneficiaryName}</td>
                    <td>{dado.referenceNumber}</td>

                    <td>{dado.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })} {dado.creditDebitIndicator}</td>
                    <td>{soma.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}C</td>

                    <td>{dado.expensesCategory[1]?.name || '-'}</td>
                    <td>{dado.expensesCategory[0]?.name || '-'}</td>
                    <td>{dado.expensesDocuments.map((nota, key) => {
                      return (
                        <button key={key}
                          onClick={() => {
                            setTransactionClicked({
                              unidade: unidade,
                              agencia: agencia,
                              conta: conta,
                              idNota: nota,
                              idTransacao: dado.id,
                              data: dado.valueDate,
                              historico: `${dado.descriptionCode} ${dado.descriptionName} ${dado.beneficiaryDocumentId} ${dado.beneficiaryName}`,
                              documento: dado.referenceNumber,
                              subCategoria1: dado.expensesCategory[1]?.name,
                              subCategoria2: dado.expensesCategory[0]?.name,
                              valor: dado.value,
                              saldo: dado.creditDebitIndicator === "D" ? -dado.value : dado.value,
                            });
                            // const dataFormatada = dado.valueDate.split('/').reverse().join('-');
                            // navigate(`/extrato/${unidade}/${agencia}/${conta}/nota/${nota}/${dado.id}/${dataFormatada}`);
                          }}
                          className={`notaButton `}>
                          <LiaFileInvoiceDollarSolid />
                          {nota}
                        </button>
                      )
                    })}</td>
                  </tr>
                )
              }
              )}
            </tbody>
          </table>
        }
      </div >
    );
  }
}


export default ResponsiveExample;