import React from 'react';
import { usePagination, DOTS } from './usePaginacao';
import { PaginationContainer, PaginationItem, Arrow } from './Paginacao.style';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer>
      <PaginationItem
        onClick={onPrevious}
        disabled={currentPage === 1}
      >
        <Arrow left />
      </PaginationItem>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <PaginationItem key={pageNumber} className="dots">&#8230;</PaginationItem>;
        }

        return (
          <PaginationItem
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            selected={pageNumber === currentPage}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
      <PaginationItem
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        <Arrow />
      </PaginationItem>
    </PaginationContainer>
  );
};

export default Pagination;
