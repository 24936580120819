import styled from "styled-components";
import { LineTitle, Title } from "../Relatorios/Saldos/Relatorio.style";

export const Background = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.color.bg[200]};
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  overflow: scroll;
  font-family: "Rawline", sans-serif;
  padding: 1rem;
  gap: 2rem;

  span { 
    color: ${props => props.theme.color.primary};
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
    }
`;

export const Container = styled.div`
  display: flex;
  min-width: fit-content;
  width: 100%;
  padding: 0.2rem 0.5rem;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid transparent;

  p {
    font-size: 0.8rem;
    padding: 0.5rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding-bottom: 1rem;
  }

  &:hover {
  border: 1px solid  ${props => props.theme.color.text[100]};
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
`;

export const GrayContainer = styled(Container)`
  background-color: ${props => props.theme.color.bg[100]};
  width: 100%;
`;

export const YellowContainer = styled(Container)`
  background-color: #FDFFE7;
  width: 100%;
`;

export const StatusContainer = styled(Container)`
  background-color: ${props => props.status == true ? "#FFD2D2" : "#d6fad6"};
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  height: fit-content;
  justify-content: space-between;

  `;

export const QrCodeContainer = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  img { 
    width: 30%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 0.3rem;
    background-color: ${props => props.theme.color.bg[300]};
  }
`;
export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  gap: 1rem; 
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  word-wrap: break-word; 
  white-space: normal; 
  text-transform: uppercase;
  width: 40rem;
  justify-content: center;
  padding: 1rem;

& > div {
  display: flex;
  gap: 1rem;
  width: 100%;
}

  p {
    font-size: 0.8rem;
    padding: 0.5rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding-bottom: 1rem;
  }
`;

export const Campo = (props) => {
  return (
    <div style={{
      display: "flex", gap: "0.5rem", justifyItems: "center", alignItems: "end"
    }}>
      <InfoTitle>{props.title}</InfoTitle>
      <InfoDescricao>{props.descricao}</InfoDescricao>
    </div >
  );
};

export const InfoTitle = styled.div`
  font-size: 0.9rem;
font-weight: 600;
text-transform: uppercase;
color: ${props => props.theme.color.text[200]};
`;


export const InfoDescricao = styled.div`
  color: #000;
  font-size: 0.9rem;
  font-weight: 600;
  flex-wrap: wrap;
  word-wrap: break-word;
  white-space: normal; 
  flex-wrap: wrap;
`;

export const WhiteContainer = styled(Container)`
  background-color: ${props => props.theme.color.bg[200]};
display: flex;
  width: 49%;
  gap: 1rem;
  height: 15rem;

  border-radius: 15px;
border: 1px solid  ${props => props.theme.color.text[300]};
box-shadow: 0px 3px 7.8px 1px rgba(0, 0, 0, 0.03);
`;



export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  font-size: 1rem;
  text-align: left;
`;

export const StyledThead = styled.thead`
`;

export const StyledTh = styled.th`
  padding: 0.5rem;
  background-color: ${props => props.theme.color.bg[100]};
  color: ${props => props.theme.color.text[200]};
  font-weight: normal;
  
  
`;

export const StyledTbody = styled.tbody`
  tr:nth-child(even) {
    background-color: ${props => props.theme.color.bg[200]};
  }
`;

export const StyledTr = styled.tr`
   background-color: ${props => props.theme.color.bg[200]} !important;
   border: 1px solid transparent;
transition: all 0.5s ease-in-out;
border-radius: 0.5rem;

  &:hover {
    background-color: ${props => props.theme.color.bg[300]};
    border: 1px solid ${props => props.theme.color.text[100]};
  }
`;

export const StyledTd = styled.td`
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.color.bg[200]};
  
  &:last-child {
    font-weight: 600;
    text-align: left;

  }

`;
