import React, { useState } from "react";
import * as T from "./tableNotaFiscal.style.jsx";

export const TransactionRow = ({ consulta, index }) => {
    const [showTransactions, setShowTransactions] = useState(false);


    return (
        <>
            <T.TabelaRow
                key={index}
                showTransactions={showTransactions && consulta?.data.totalTransacoes > 0}
                active={showTransactions && consulta?.data.totalTransacoes > 0}
                onClick={() => setShowTransactions(!showTransactions)}
            >
                <T.TabelaDataCell width={"4rem"}>{index + 1}</T.TabelaDataCell>
                <T.TabelaDataCell>{consulta?.nome}</T.TabelaDataCell>
                <T.TabelaDataCell>{consulta?.municipio}</T.TabelaDataCell>
                <T.TabelaDataCell>{consulta?.convenio}</T.TabelaDataCell>
                <T.TabelaDataCell>{consulta?.data.totalTransacoes}</T.TabelaDataCell>
                <T.TabelaDataCell>
                    {consulta?.data.valoresDeEntrada !== undefined
                        ? consulta?.data.valoresDeEntrada.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })
                        : "R$ 0,00"}
                </T.TabelaDataCell>
                <T.TabelaDataCell>
                    {consulta?.data.valoresDeSaida !== undefined
                        ? consulta?.data.valoresDeSaida.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        })
                        : "R$ 0,00"}
                </T.TabelaDataCell>
                <T.TabelaDataCell>{consulta?.data.quantNotasFiscais}</T.TabelaDataCell>
            </T.TabelaRow>
            {showTransactions && consulta?.data.totalTransacoes > 0 && (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <T.Tabela>
                        <T.TabelaHeader>
                            <T.TabelaRow showTransactions={showTransactions}>
                                <T.TabelaHeaderCell>Data</T.TabelaHeaderCell>
                                <T.TabelaHeaderCell>Valor</T.TabelaHeaderCell>
                                <T.TabelaHeaderCell>Descrição</T.TabelaHeaderCell>
                                <T.TabelaHeaderCell>Nota fiscal</T.TabelaHeaderCell>
                            </T.TabelaRow>
                        </T.TabelaHeader>
                        <T.TabelaBody>
                            {consulta?.data.transactions?.map((transacao, index) => (
                                <T.TabelaRow key={index} showTransactions={showTransactions}>
                                    <T.TabelaDataCell>{transacao?.valueDate}</T.TabelaDataCell>
                                    <T.TabelaDataCell>{transacao?.value !== undefined
                                        ? transacao?.value.toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                        })
                                        : "R$ 0,00"}</T.TabelaDataCell>
                                    <T.TabelaDataCell>{transacao?.descriptionName}</T.TabelaDataCell>
                                    <T.TabelaDataCell>
                                        {transacao?.expensesDocuments?.map((notaFiscal, index) => (
                                            <div key={index}>
                                                <span>{notaFiscal}</span>
                                            </div>
                                        ))}
                                    </T.TabelaDataCell>
                                </T.TabelaRow>
                            ))}
                        </T.TabelaBody>
                    </T.Tabela>
                </div>
            )}
        </>
    );
};