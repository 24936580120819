import axios from "axios";
// import { toast } from "sonner";

export default class APIBBService {
    token: string | null;
    url: string | null;
    headers: { authorization: string };

    constructor() {
        this.url = `${process.env.REACT_APP_FEE}/bb`;

        const dados = sessionStorage.getItem("token");

        this.token = dados ? JSON.parse(dados) : null;
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
    }

    async buscarNota(agencia: string, conta: string, unidade: string, idNota: string, idTransacao: string, data: string) {
        try {
            const dataFormatada = data.split('-').reverse().join('/');
            const response = await axios.post
                (`${this.url}/notaFiscal`, {
                    agencia: agencia.slice(0, -1),
                    conta: conta.slice(0, -1),
                    id: Number(idTransacao),
                    numeroDocumento: Number(idNota),
                    bookingData: dataFormatada
                });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}
