import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import logo from "../../img/SEEC - vertical.png";
import { consultaMock } from "../../pages/Relatorios/NotaFiscal/consultaMock";

// Define a configuração de fonte uma vez
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function RelatorioNotasFiscaisPDF(dadosConsultados) {
  console.log(dadosConsultados);

  const dadosMapeados = dadosConsultados.map((consulta, index) => {
    // Alterna cores de fundo para melhor visualização
    const fillColor = index % 2 === 0 ? "#f0f0f0" : "#FFFFFF";

    return [
      {
        table: {
          widths: ["25%", "25%", "25%", "25%"],
          body: [
            [
              { text: "ID", bold: true, alignment: "center", fontSize: 12 },
              { text: "Nome", bold: true, alignment: "center", fontSize: 12 },
              { text: "Convênio", bold: true, alignment: "center", fontSize: 12 },
              { text: "Município", bold: true, alignment: "center", fontSize: 12 },
            ],
            [
              { text: index + 1, fillColor, alignment: "center", fontSize: 12 },
              { text: consulta.nome, fillColor, alignment: "center", fontSize: 12 },
              { text: consulta.convenio, fillColor, alignment: "center", fontSize: 12 },
              { text: consulta.municipio, fillColor, alignment: "center", fontSize: 12 },
            ],
          ],
        },
        margin: [0, 10, 0, 10], // Espaço entre cada escola
      },
      {
        text: "Infos",
        bold: true,
        fontSize: 12,
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Data", bold: true, alignment: "center", fontSize: 10 },
              { text: "Valor", bold: true, alignment: "center", fontSize: 10 },
              { text: "Descrição", bold: true, alignment: "center", fontSize: 10 },
              { text: "Possui nota", bold: true, alignment: "center", fontSize: 10 },
              { text: "Categoria", bold: true, alignment: "center", fontSize: 10 },
              { text: "Subcategoria", bold: true, alignment: "center", fontSize: 10 },
            ],
            ...consulta.data.map((transacao) => [
              { text: transacao.valueDate, alignment: "center", fontSize: 10 },
              { text: transacao.value, alignment: "center", fontSize: 10 },
              { text: transacao.descriptionName, alignment: "center", fontSize: 10 },
              {
                text: transacao.expensesDocuments && transacao.expensesDocuments.length
                  > 0 ? "Sim" : "Não", alignment: "center", fontSize: 10
              },
              { text: transacao.category, alignment: "center", fontSize: 10 },
              { text: transacao.subcategory, alignment: "center", fontSize: 10 },
            ]),
          ],
        },
        layout: "lightHorizontalLines",
        margin: [0, 0, 0, 20], // Espaço após cada tabela de transações
      },
      {
        text: "Transações",
        bold: true,
        fontSize: 12,
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Data", bold: true, alignment: "center", fontSize: 10 },
              { text: "Valor", bold: true, alignment: "center", fontSize: 10 },
              { text: "Descrição", bold: true, alignment: "center", fontSize: 10 },
              { text: "Possui nota", bold: true, alignment: "center", fontSize: 10 },
              { text: "Categoria", bold: true, alignment: "center", fontSize: 10 },
              { text: "Subcategoria", bold: true, alignment: "center", fontSize: 10 },
            ],
            ...consulta.data.transactions.map((transacao) => [
              { text: transacao.valueDate, alignment: "center", fontSize: 10 },
              { text: transacao.value, alignment: "center", fontSize: 10 },
              { text: transacao.descriptionName, alignment: "center", fontSize: 10 },
              {
                text: transacao.expensesDocuments && transacao.expensesDocuments.length
                  > 0 ? "Sim" : "Não", alignment: "center", fontSize: 10
              },
              { text: transacao.category, alignment: "center", fontSize: 10 },
              { text: transacao.subcategory, alignment: "center", fontSize: 10 },
            ]),
          ],
        },
        layout: "lightHorizontalLines",
        margin: [0, 0, 0, 20], // Espaço após cada tabela de transações
      },
    ];
  });

  const docDefinition = {
    pageSize: "A4",
    alignment: "center",
    header: {
      text: `\n Relatório de Notas Fiscais`,
      fontSize: 16,
      bold: true,
      color: "#01436A",
      margin: [0, 0, 10, 10],
      alignment: "center",
    },
    content: [
      {
        stack: [
          {
            text: "Secretaria de Estado da Educação, do Esporte e do Lazer do Rio Grande do Norte",
            alignment: "center",
          },
          {
            text: `Data: ${new Date().toLocaleDateString()} Hora: ${new Date().toLocaleTimeString()}`,
            alignment: "center",
          },
        ],
      },
      {
        text: "\n", // Espaço entre os elementos
      },
      ...dadosMapeados.flat(),
    ],
    footer: function (currentPage, pageCount) {
      return {
        margin: [30, 20, 30, 0],
        stack: [
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 2,
                lineColor: "#3AA25F",
              },
            ],
          },
          {
            text: ["Produzido pelo Grupo de Processamentos de Dados \n Grupo DEV"],
            alignment: "center",
            style: {
              fontSize: 10,
              bold: true,
              color: "#01436A",
            },
            margin: [0, 5, 0, 0],
          },
        ],
      };
    },
  };

  pdfMake.createPdf(docDefinition).open();
}
