import * as Popover from "@radix-ui/react-popover";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import React, { useCallback, useEffect, useState } from "react";
import { IoFilter, IoPrintSharp } from "react-icons/io5";
import { toast } from "sonner";
import { Button } from "../../../components/globalComponents.style.jsx";
import { RelatorioNotasFiscaisPDF } from "../../../components/Relatorio/RelatorioNotasFiscaisPDF.jsx";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";
import { FormConsultaExtrato } from "../../Extrato/Extrato.style.jsx";
import { InputsExtrato } from "../../Extrato/InputsExtrato/InputsExtrato.jsx";
import { BodyStyle } from "../../Home/Home.style.jsx";

import {
  BoxContainer,
  HeaderTitle,
  RelatorioContainer,
} from "../Saldos/Relatorio.style.jsx";
import { PopUpFiltragem } from "./PopUpFiltragem.jsx";
import { Table } from "./Table.jsx";

const RelatorioNotaFiscal = () => {
  const { escolasPermitidas, direcsPermitidas, draesPermitidas } =
    useMyPermissoesContext();

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    consultasRealizadas: [],
    direcs: [],
    draes: [],
    programasGoverno: [],
  });
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [escolasCalculadas, setEscolasCalculadas] = useState(0);
  const [unidadesACalcular, setUnidadesACalcular] = useState([]);
  const [filtrosParaSelecionar, setFiltrosParaSelecionar] = useState({
    direc: "",
    drae: "",
    programaGoverno: "",
  });
  const [tipoUnidadeSelecionada, setTipoUnidadeSelecionada] = useState();
  const [botaoFiltro, setBotaoFiltro] = useState(false);

  const definirFiltrosParaSelecionar = useCallback(() => {
    const direcsParaSelecionar = [];
    const draesParaSelecionar = [];
    const programasGovernoParaSelecionar = ["TODOS OS CONVÊNIOS"];

    escolasPermitidas.forEach((escola) => {
      if (!direcsParaSelecionar.includes(escola.direc.nome)) {
        direcsParaSelecionar.push(escola.direc.nome);
      }
      if (!draesParaSelecionar.includes(escola.drae.nome)) {
        draesParaSelecionar.push(escola.drae.nome);
      }
      if (!programasGovernoParaSelecionar.includes(escola.convenio)) {
        programasGovernoParaSelecionar.push(escola.convenio);
      }
    });

    setFiltrosParaSelecionar({
      direc: direcsParaSelecionar.map((direc) => ({
        value: direc,
        label: direc,
      })),
      drae: draesParaSelecionar.map((drae) => ({ value: drae, label: drae })),
      programaGoverno: programasGovernoParaSelecionar.map((programa) => ({
        value: programa,
        label: programa,
      })),
    });
  }, [escolasPermitidas]);

  useEffect(() => {
    definirFiltrosParaSelecionar();
  }, [definirFiltrosParaSelecionar]);

  // useEffect(() => {
  //   filtrarPorUnidade()
  //   filtrarPorConvenio()
  // }, [filtrosSelecionados.direc,
  // filtrosSelecionados.drae,
  // filtrosSelecionados.programaGoverno,
  // filtrosSelecionados.unidade]);

  var optionsUnidades = [];

  if (direcsPermitidas.length > 0) {
    optionsUnidades.push({ value: "DIREC", label: "DIREC" });
  }
  if (draesPermitidas.length > 0) {
    optionsUnidades.push({ value: "DRAE", label: "DRAE" });
  }
  if (escolasPermitidas.length > 0) {
    optionsUnidades.push({ value: "ESCOLA", label: "ESCOLA" });
  }

  function filtrarPorUnidade(filtro) {
    if (filtro === "DIREC") {
      setUnidadesACalcular(direcsPermitidas);
    } else if (filtro === "DRAE") {
      setUnidadesACalcular(draesPermitidas);
    } else if (filtro === "ESCOLA") {
      setUnidadesACalcular(escolasPermitidas);
    }
  }

  function filtrarPorConvenio(filtroConvenio) {
    filtrarPorUnidade(tipoUnidadeSelecionada);

    if (filtroConvenio !== "TODOS OS CONVÊNIOS" && filtroConvenio !== "") {
      setUnidadesACalcular(
        unidadesACalcular.filter(
          (unidade) => unidade.convenio === filtroConvenio
        )
      );
    }
  }

  const realizarConsulta = async () => {
    setEscolasCalculadas(0);

    if (!dataInicial || !dataFinal) {
      toast.error("Preencha todos os campos");
      return;
    }

    if (loading) {
      toast.info("Aguarde a consulta anterior finalizar");
      return;
    }

    setLoading(true);

    if (unidadesACalcular.length === 0) {
      toast.error("Nenhuma unidade encontrada. Verifique os filtros.");
    } else {
      await calcularUnidades();
    }

    setLoading(false);
  };

  async function gerarRelatorioPDF() {
    console.log(dados.consultasRealizadas);
    RelatorioNotasFiscaisPDF(dados.consultasRealizadas);
  }

  async function calcularUnidades() {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const newDados = [];

    const api = axios.create({
      baseURL: process.env.REACT_APP_FEE,
      headers: { Authorization: `Bearer ${token}` },
    });

    try {
      const totalUnidades = unidadesACalcular.length;

      const SMALL_N_THRESHOLD = 50;
      const BATCH_SIZE = 20;

      const unidades = unidadesACalcular.map((unidade) => ({
        agencia: unidade.agencia.slice(0, -1),
        conta: unidade.conta.slice(0, -1),
        nome: unidade.nome,
        convenio: unidade.convenio,
        municipio: unidade.municipio,
      }));

      const processarUnidade = async (unidade) => {
        try {
          const res = await api.post("/escola/relatorio/notas-fiscais", {
            dadosConsulta: {
              agencia: unidade.agencia,
              conta: unidade.conta,
              dataInicial,
              dataFinal,
              tipoConsulta: "ExtratoOrcaoRepasseProgramaDoGoverno",
            },
          });

          setEscolasCalculadas((prev) => prev + 1);

          return {
            nome: unidade.nome,
            convenio: unidade.convenio,
            municipio: unidade.municipio,
            data: res.data,
          };
        } catch (error) {
          console.error("Erro em", unidade.nome, error);
          return null;
        }
      };

      if (totalUnidades <= SMALL_N_THRESHOLD) {
        const promises = unidades.map(processarUnidade);
        const results = await Promise.allSettled(promises);

        newDados.push(
          ...results
            .filter((r) => r.status === "fulfilled" && r.value)
            .map((r) => r.value)
        );
      } else {
        for (let i = 0; i < unidades.length; i += BATCH_SIZE) {
          const batch = unidades.slice(i, i + BATCH_SIZE);
          const promises = batch.map(processarUnidade);
          const batchResults = await Promise.allSettled(promises);

          newDados.push(
            ...batchResults
              .filter((r) => r.status === "fulfilled" && r.value)
              .map((r) => r.value)
          );
        }
      }

      setDados((prev) => ({
        ...prev,
        consultasRealizadas: newDados,
      }));
    } catch (error) {
      toast.error("Falha crítica no processo");
      console.error(error);
    }
  }

  return (
    <>
      <Popover.Root>
        <BodyStyle>
          <HeaderTitle title="RELATÓRIO NOTAS FISCAIS" />
          <BoxContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                width: "100%",
                justifyContent: "end",
                alignItems: "center",
                padding: "1%",
              }}
            >
              <FormConsultaExtrato
                style={{ justifyContent: "start", gap: "1%" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  realizarConsulta();
                }}
              >
                <InputsExtrato
                  type={1}
                  options={optionsUnidades}
                  onChange={(option) => {
                    setTipoUnidadeSelecionada(option.value);
                    filtrarPorUnidade(option.value);
                  }}
                  value={optionsUnidades.find((option) => option.value)}
                  placeholder="Escolha uma unidade"
                />
                <InputsExtrato
                  label="DATA DE INÍCIO *"
                  type={2}
                  isDate={true}
                  id="dataInicial"
                  selected={dataInicial}
                  date={dataInicial}
                  placeholder="Data inicial"
                  onChange={(date) => setDataInicial(date)}
                />

                <InputsExtrato
                  label="DATA DE FIM *"
                  type={2}
                  isDate={true}
                  id="dataFinal"
                  selected={dataFinal}
                  date={dataFinal}
                  placeholder="Data final"
                  onChange={(date) => setDataFinal(date)}
                  minDate={dataInicial}
                />

                <Button type="submit" style={{ width: "10%" }}>
                  Consultar
                </Button>
              </FormConsultaExtrato>
            </div>
          </BoxContainer>
          <BoxContainer>
            {tipoUnidadeSelecionada ? (
              <div>
                {unidadesACalcular.length} {tipoUnidadeSelecionada}S para
                calcular{" "}
              </div>
            ) : (
              "Nenhuma unidade selecionada"
            )}

            {botaoFiltro && (
              <PopUpFiltragem
                setBotaoFiltro={setBotaoFiltro}
                botaoFiltro={botaoFiltro}
                filtrosParaSelecionar={filtrosParaSelecionar}
                unidadesACalcular={unidadesACalcular}
                tipoUnidadeSelecionad={tipoUnidadeSelecionada}
                direcsPermitidas={direcsPermitidas}
                filtrarPorConvenio={filtrarPorConvenio}
              />
            )}

            <div
              style={{
                display: "flex",
                height: "fit-content",
                gap: "2%",
              }}
            >
              <Button
                height="35px"
                width="10%"
                primary
                onClick={() => setBotaoFiltro(!botaoFiltro)}
                // onClick={() => gerarRelatorioPDF()}
              >
                {" "}
                <IoFilter /> Filtros
              </Button>
              <Button
                height="35px"
                width="10%"
                onClick={() => gerarRelatorioPDF()}
              >
                {" "}
                <IoPrintSharp /> Imprimir
              </Button>
            </div>
          </BoxContainer>

          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "10%",
                justifyContent: "center",
                padding: "1%",
              }}
            >
              {escolasCalculadas}/{unidadesACalcular.length} unidades
              consultadas. Aguarde...
            </div>
          ) : (
            <RelatorioContainer>
              <Table dados={dados.consultasRealizadas ?? []} />
            </RelatorioContainer>
          )}
        </BodyStyle>
      </Popover.Root>
    </>
  );
};

export default RelatorioNotaFiscal;
