import React, { useId, forwardRef } from 'react';
import { Container, CustomSelect, CustomDatePicker } from "./InputsExtrato.style"

const customStyles = {
    control: (provided) => ({
        ...provided,
        appearance: "none",
        border: "none",
        background: "transparent",
        borderRadius: 0,
        color: "black",
        padding: 0,
        minWidth: "5.5rem",
        boxShadow: "none",
        "&:hover": {
            borderColor: "none",
        },
    }),
    menu: (provided) => ({
        ...provided,
        border: "none",
        width: "fit-content",
        wrap: "wrap",
        whiteSpace: "nowrap",
        fontSize: 14,
        padding: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "yellow" : "blue",
    }),
    placeholder: (provided) => ({
        ...provided,

    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
        appearance: "none",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    input: (provided) => ({
        ...provided,
    }),
};


export const InputsExtrato
    = forwardRef(({ label, type, isDate, options, onChange, value, ...props }, ref) => {
        const inputId = useId();
        return (
            <Container>
                {type === 2 && <label>{label}</label>}
                {isDate ?
                    <CustomDatePicker
                        className="my-datepicker"
                        ref={ref}
                        styles={customStyles}
                        id={inputId}
                        onChange={onChange}
                        selected={props.date}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        {...props} />
                    : <CustomSelect
                        ref={ref}
                        id={inputId}
                        styles={customStyles}
                        type={type}
                        placeholder="Selecione"
                        options={options || []}
                        autoComplete="off"
                        onChange={(selectedOption) => onChange(selectedOption)}
                        {...props}
                    />}

            </Container>

        )
    });