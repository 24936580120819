import axios from "axios";

export async function ExtratoProgramaGoverno(
    dataInicial,
    dataFinal,
    operacao,
    agencia,
    conta,
    token
) {
    let data = JSON.stringify({
        agencia: agencia?.replace(/.$/, ""),
        conta: conta?.replace(/.$/, ""),
        dataIncio: dataInicial,
        dataFim: dataFinal,
    });

    let config = {
        method: "post",
        url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
        headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        data: data,
    };
    const resp = await axios(config);
    return resp;
}
