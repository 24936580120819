import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import axios from "axios";
import { ExtratoBancario } from "./ExtratoBancario.jsx";
import { NotaFiscal } from "../../pages/NotaFiscal/NotaFiscal.jsx";


const FormConsulta2 = (props) => {
    const [transactionClicked, setTransactionClicked] = useState(null);
    const [ultimasConsultas, setUltimasConsultas] = useState([]);

    return (
        <>
            {transactionClicked ?
                <NotaFiscal
                    transactionClicked={transactionClicked}
                    setTransactionClicked={setTransactionClicked} />
                : <ExtratoBancario
                    transactionClicked={transactionClicked}
                    setTransactionClicked={setTransactionClicked}
                    setUltimasConsultas={setUltimasConsultas}
                    ultimasConsultas={ultimasConsultas}
                />}
        </>
    );
};

export default FormConsulta2;
